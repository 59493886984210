// This file can be replaced during build by using the fileReplacements array.
// ng build --prod replaces environment.ts with environment.prod.ts.
// The list of file replacements can be found in angular.json.

import { SupportedApps } from 'src/app/models/SupportedApp.model';
import { SupportedEnvironment } from 'src/app/models/SupportedEnvironment.model';

export const environment = {
  production: false,
  app_name: SupportedApps.myDEUTZFAHR,
  env: SupportedEnvironment.develop,
  bundle_id: "dev.touchwa.sdfdeutzfahr",
  // app_id: "fab9bafd-ff8a-46eb-96ac-9cdbc542fb61",
  // apiUrl: 'http://157.230.127.220:3000/'
  //app_id: '62517eb9-264d-4ca0-8ebd-c62a0eaa8117',
  // apiUrl: 'https://myapp-api.sdfgroup.com/'
  app_id: 'fab9bafd-ff8a-46eb-96ac-9cdbc542fb61',
  apiUrl: 'https://staging.api.sdf.touchwa.re/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as zone.run, zoneDelegate.invokeTask.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.